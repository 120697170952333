import { useEffect, useState } from "react";
import {
  getNotificationList,
  updateNotification,
} from "../../endpoints/notification.service";

const NotificationPage = () => {
  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleNotificationClick = (notificationId) => {
    updateNotification({ notificationId }).then((res) => {
      const { success } = res;
      if (success) {
        setNotificationList((prev) =>
          prev?.map((item) => {
            if (item?._id === notificationId) {
              return { ...item, status: "read" };
            } else {
              return item;
            }
          })
        );
      }
    });
  };

  useEffect(() => {
    getNotificationList()
      .then((res) => {
        setNotificationList(res?.data?.reverse());
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="rounded-lg bg-white w-full flex-grow p-4">
      <h3 className="font-semibold text-xl">Notifications</h3>
      <div className="py-4">
        {notificationList?.map((notification, notificationIndex) => {
          return (
            <div
              key={notification?._id}
              onClick={() => handleNotificationClick(notification?._id)}
              className={`flex flex-col p-2 hover:bg-gray-50 cursor-pointer ${
                notificationIndex !== 0 ? "border-t" : ""
              }`}
            >
              <div className="flex items-center justify-between">
                <span className="flex items-center gap-2 font-semibold text-sm text-primary">
                  {notification?.type}
                </span>
                {notification?.status === "unread" ? (
                  <div className="h-2 w-2 rounded-full bg-secondary" />
                ) : null}
              </div>
              <span className="font-medium text-xs text-light-black">
                {notification?.message}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NotificationPage;
