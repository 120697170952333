import api from "../api";
import axios from "../utils/axios.instance";
import handleError from "./handle-error";

export const getNotificationList = async () => {
  try {
    const { data } = await axios.post(api.getNotificationList);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const updateNotification = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateNotification, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
