import {
  createBrowserRouter,
  Navigate,
  redirect,
  RouteObject,
} from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAxiosInstance } from "../utils/setupInterceptor";
import {
  RecruiterApprovalStatus,
  RecruiterTypes,
  userTypes,
} from "../utils/constants";

import Layout from "../pages/Layout/Layout";
import LandingPage from "../pages/LandingPage/LandingPage";
import LoginPage from "../pages/LoginPage/LoginPage";
import RegisterPage from "../pages/RegisterPage/ResgisterPage";
import DashboardPage from "../pages/Dashboard/Dashboard";
import ContactsPage from "../pages/ContactsPage/ContactsPage";
import AddCandidatePage from "../pages/ContactsPage/CandidatesSection/AddCandidatePage/AddCandidatePage";
import EmailVerification from "../pages/EmailVerification/EmailVerification";
import MyAssessmentsPage from "../pages/MyAssessmentsPage/MyAssessmentsPage";
import CalendarsPage from "../pages/CalendarsPage/CalendarsPage";
import CreateAssessment from "../pages/MyAssessmentsPage/CreateAssessmentSection/CreateAssessmentSection";
import MyAssessmentsDetailsPage from "../pages/MyAssessmentsDetailsPage/MyAssessmentsDetailsPage";
import CandidateAssessments from "../pages/MyAssessmentsPage/CandidateAssessments/CandidateAssessments";
import AnswerAssessment from "../pages/MyAssessmentsPage/AnswerAssessment/AnswerAssessment";
import Profile from "../pages/ProfilePage/ProfilePage";
import AddCompanyPage from "../pages/ContactsPage/CompaniesSection/AddCompanyPage/AddCompanyPage";
import JobPage from "../pages/JobPage/JobPage";
import JobDetail from "../pages/JobPage/JobDetail/JobDetail";
import CandidateSection from "../pages/ContactsPage/CandidatesSection/CandidatesSection";
import CompaniesSection from "../pages/ContactsPage/CompaniesSection/CompaniesSection";
import AssessmentListDetails from "../pages/MyAssessmentsPage/AssessmentListDetails/AssessmentListDetails";
import AssessmentView from "../pages/MyAssessmentsPage/AssessmentView/AssessmentView";
import { JobPostSection } from "../pages/JobPage/JobDetail/JobDetailSection/JobPostSection";
import ApplicantSection from "../pages/JobPage/JobDetail/JobDetailSection/ApplicantSection";
import CreateProfile from "../pages/CreateProfile/CreateProfile";
import AgentProfile from "../pages/ProfilePage/Agent/AgentProfile";
import JobsListPage from "../pages/JobsListPage/JobsListPage";
import VerifyUserJobPage from "../pages/VerifyUserJobPage/VerifyUserJobPage";
import PublicJobDetailPage from "../pages/PublicJobDetailPage/PublicJobDetailPage";
import JobTrackPage from "../pages/JobTrackPage/JobTrackPage";
import JobTrackDetail from "../pages/JobTrackPage/JobTrackDetail/JobTrackDetail";
import UnauthorizedPage from "../pages/UnauthorizedPage/UnauthorizedPage";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import ChangePasswordPage from "../pages/ChangePasswordPage/ChangePasswordPage";
import AccountMembers from "../pages/AccountMembersPage/AccountMembers";
import AccountMembersDetail from "../pages/AccountMembersPage/AccountMembersDetail/AccountMembersDetail";
import ViewAgentProfile from "../pages/ViewAgentProfile/ViewAgentProfile";
import AccountPage from "../pages/AccountPage/AccountPage";
import MyAccountDetailsPage from "../pages/MyAccountDetailsPage/MyAccountDetailsPage";
import MyCompanyDetailsPage from "../pages/MyCompanyDetailsPage/MyCompanyDetailsPage";
import RecruiterCreateProfilePage from "../pages/RecruiterCreateProfilePage/RecruiterCreateProfilePage";
import SearchLayout from "../pages/SearchPage/SearchLayout";
import SearchPage from "../pages/SearchPage/SearchPage";
import PossibleMatchPage from "../pages/SearchPage/PossibleMatchPage";
import RequestedDetailsPage from "../pages/SearchPage/RequestedDetailsPage";
import LoginAmbassadorPage from "../pages/Ambassador/Login/Login";
import RegisterAmbassadorPage from "../pages/Ambassador/Register/Register";
import NetworkPage from "../pages/Ambassador/Network/Network";
import NetworkDetailPage from "../pages/Ambassador/Network/Detail/Detail";
import MyAccountSettingsPage from "../pages/MyAccountSettingsPage/MyAccountSettingsPage";
import MatchesPage from "../pages/MatchesPage/MatchesPage";
import Matches2Page from "../pages/Matches2Page/Matches2Page";
import MatchDetailPage from "../pages/MatchDetailPage/MatchDetailPage";
import DetailPage from "../pages/SearchPage/DetailPage";
import TemplateResultPage from "../pages/SearchPage/TemplateResultPage";
import TemplateSearchesPage from "../pages/SearchPage/TemplateSearchesPage";
import CreateJobPage2 from "../pages/CreateJobPage2/CreateJobPage2";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import UpdateForgotPassword from "../pages/UpdateForgotPassword/UpdateForgotPassword";
import GenerateUserNamePage from "../pages/GenerateUserNamePage/GenerateUserNamePage";
import JobApplyPage from "../pages/JobApplyPage/JobApplyPage";
import JobApplicantDetail from "../pages/JobPage/JobApplicantDetail/JobApplicantDetail";
import { isNullOrEmpty } from "../utils/helperFunction";
import AddReferralPage from "../pages/AddReferralPage/AddReferralPage";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import MembersProfilePage from "../pages/MembersProfilePage/MembersProfilePage";
import MessageView from "../pages/MembersProfilePage/components/MessageView";
import MatchesDetailsPage from "../pages/MatchesPage/MatchesDetailsPage";
import ShortlistedSection from "../pages/JobPage/JobDetail/JobDetailSection/ShortlistedSection";
import CheckoutPage from "../pages/Stripe/CheckoutPage";
import ReferralPage from "../pages/Stripe/ReferralPage";
import PaymentMethodPage from "../pages/Stripe/PaymentMethodPage";
import PaymentPage from "../pages/Stripe/PaymentPage";
import BalancePage from "../pages/Stripe/BalancePage";
import NotificationPage from "../pages/NotificationPage/NotificationPage";

function useAuthLoader() {
  const auth = useSelector((state: any) => state.auth);
  const { authenticated, user } = auth;

  const loginLoader = ({ request }: any) => {
    if (authenticated && user && user?._id && !isNullOrEmpty(user?.userName)) {
      return redirect("/");
    }
    return null;
  };

  const authLoader = ({ request }: any) => {
    if (!authenticated && !user && isNullOrEmpty(user?._id)) {
      return redirect("/login");
    }

    if (authenticated && user && user?._id && isNullOrEmpty(user?.userName)) {
      return redirect("/generate-user-name");
    }
    return null;
  };

  return {
    loginLoader,
    authLoader,
  };
}

function useRoutes() {
  const auth = useSelector((state: any) => state.auth);
  const { loginLoader, authLoader } = useAuthLoader();
  useAxiosInstance();

  const isLoggedIn =
    (auth.authenticated && auth.user && auth.user._id) ||
    auth?.user?.userType === userTypes.REFERRAL_AMBASSADOR;

  const userNameNotExist = isLoggedIn && isNullOrEmpty(auth?.user?.userName);

  const isRecruiter = auth?.user?.userType === userTypes.RECRUITER;
  const isCandidate = auth?.user?.userType === userTypes.CANDIDATE;
  const isReferrealAmbassador =
    auth?.user?.userType === userTypes.REFERRAL_AMBASSADOR;
  const isRecruiterAdmin =
    isRecruiter && auth?.user?.recruiterType === RecruiterTypes?.ADMIN;

  let layoutChildren: RouteObject[] = [
    {
      path: "/",
      element: isLoggedIn ? (
        isRecruiterAdmin &&
        (!auth?.user?.recruiterApprovalStatus ||
          auth?.user?.recruiterApprovalStatus ===
            RecruiterApprovalStatus.REQUESTED) ? (
          <Navigate to="/create-profile" />
        ) : userNameNotExist ? (
          <Navigate to="/generate-user-name" />
        ) : isCandidate && auth?.user?.enableDefaultProfileOption ? (
          // Candidate
          <Navigate to="/create-profile" />
        ) : isReferrealAmbassador ? (
          <Navigate to="/network" />
        ) : isCandidate ? (
          <Navigate to="/job-search" />
        ) : (
          isRecruiter && <Navigate to="/search" />
        )
      ) : (
        <LandingPage />
      ),
    },
    /**
     * Stripe route starts
     */

    {
      path: "/checkout",
      loader: authLoader,
      element: <CheckoutPage />,
    },
    {
      path: "/referral",
      loader: authLoader,
      element: <ReferralPage />,
    },
    {
      path: "/payment-method",
      loader: authLoader,
      element: <PaymentMethodPage />,
    },
    {
      path: "/payment",
      loader: authLoader,
      // element:<div/>
      element: <PaymentPage />,
    },
    {
      path: "/balance",
      loader: authLoader,
      element: <BalancePage />,
    },
    {
      path: "/notifications",
      loader: authLoader,
      element: <NotificationPage />,
    },
    /**
     * Stripe route ends
     */
    {
      path: "/login",
      loader: loginLoader,
      element: <LoginPage />,
    },
    {
      path: "/login-ambassador",
      loader: loginLoader,
      element: <LoginAmbassadorPage />,
    },
    {
      path: "/email-verification",
      loader: loginLoader,
      element: <EmailVerification />,
    },
    {
      path: "/forgot-password",
      loader: loginLoader,
      element: <ForgotPassword />,
    },
    {
      path: "/update-forgot-password",
      loader: loginLoader,
      element: <UpdateForgotPassword />,
    },
    {
      path: "/generate-user-name",
      loader: loginLoader,
      element: <GenerateUserNamePage />,
    },
    {
      path: "/add-referral",
      loader: loginLoader,
      element: <AddReferralPage />,
    },
    {
      path: "/register",
      loader: loginLoader,
      element: <RegisterPage />,
    },
    {
      path: "/register/:path",
      loader: loginLoader,
      element: <RegisterPage />,
    },
    {
      path: "/register-ambassador",
      loader: loginLoader,
      element: <RegisterAmbassadorPage />,
    },
    {
      path: "/verify-job/:jobId/:email",
      element: <VerifyUserJobPage />,
    },
    {
      path: "/create-profile",
      loader: authLoader,
      element:
        auth?.user?.userType === 2 ? (
          <RecruiterCreateProfilePage />
        ) : (
          <CreateProfile />
        ),
    },
    {
      path: "/dashboard",
      loader: authLoader,
      element: <DashboardPage />,
    },
    {
      path: "/matches",
      loader: authLoader,
      children: [
        {
          index: true,
          element: <MatchesPage />,
        },
        {
          path: ":candidateId/:jobId",
          loader: authLoader,
          element: <MatchesDetailsPage />,
        },
      ],
    },
    {
      path: "/matches-2",
      loader: authLoader,
      children: [
        {
          index: true,
          element: <Matches2Page />,
        },
        {
          path: "match-detail",
          loader: authLoader,
          element: <MatchDetailPage />,
        },
      ],
    },
    {
      path: "/unauthorized",
      loader: authLoader,
      element: <UnauthorizedPage />,
    },
    {
      path: "/search",
      loader: authLoader,
      element: <SearchLayout />,
      children: [
        {
          index: true,
          element: <SearchPage />,
        },
        {
          path: "possible-match",
          element: <PossibleMatchPage />,
        },
        {
          path: "template-result",
          element: <TemplateResultPage />,
        },
        {
          path: "template-searches",
          element: <TemplateSearchesPage />,
        },
        {
          path: "requested-details",
          element: <RequestedDetailsPage />,
        },
      ],
    },
    {
      path: "/search/match-detail",
      element: <DetailPage />,
    },
    {
      path: "/search/search-user-profile",
      element: <NetworkDetailPage />,
    },
    {
      path: "/contacts",
      loader: authLoader,
      children: [
        {
          path: "",
          element: <ContactsPage />,
          children: [
            {
              path: "/contacts/candidates",
              element: <CandidateSection />,
            },
            {
              path: "/contacts/companies",
              element: <CompaniesSection />,
            },
          ],
        },
        {
          path: "add-candidate",
          element: <AddCandidatePage />,
        },
        {
          path: "edit-candidate/:candidateId",
          element: <AddCandidatePage />,
        },
        {
          path: "add-company",
          element: <AddCompanyPage />,
        },
        {
          path: "edit-company/:companyId",
          element: <AddCompanyPage />,
        },
      ],
    },
    {
      path: "/my-assessments",
      loader: authLoader,
      children: [
        {
          index: true,
          element: <MyAssessmentsPage />,
        },
        {
          path: "assessment-details/:id",
          element: <MyAssessmentsDetailsPage />,
        },
        {
          path: "create-assessment",
          element: <CreateAssessment />,
        },
        {
          path: "edit-assessment/:assessmentId",
          element: <CreateAssessment />,
        },
        {
          path: "assessment-details",
          element: <AssessmentListDetails />,
        },
        {
          path: "assessment-view/:assessmentId",
          element: <AssessmentView />,
        },
        {
          path: "candidate-Assessments",
          element: <CandidateAssessments />,
        },
        {
          path: "candidate-Assessments/:id",
          element: <AnswerAssessment />,
        },
      ],
    },
    {
      path: "/profile",
      loader: authLoader,
      children: [
        {
          index: true,
          element: <AgentProfile />,
        },
      ],
    },
    {
      path: "/view-profile",
      loader: authLoader,
      children: [
        {
          index: true,
          element: <ViewAgentProfile />,
        },
      ],
    },
    {
      path: "/calendar",
      loader: authLoader,
      children: [
        {
          index: true,
          element: <CalendarsPage />,
        },
      ],
    },
    {
      path: "/job-search",
      children: [
        {
          index: true,
          loader: authLoader,
          element: <JobsListPage />,
        },
        {
          path: ":id",
          loader: authLoader,
          element: <JobApplyPage />,
        },
      ],
    },
    {
      path: "job-detail/:jobId",
      element: <PublicJobDetailPage />,
    },
    {
      path: "/job",
      loader: authLoader,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute allowedRoles={[2]} user={auth?.user}>
              <JobPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "create",
          loader: authLoader,
          element: <CreateJobPage2 />,
        },
        {
          path: ":jobId",
          loader: authLoader,
          element: (
            <ProtectedRoute allowedRoles={[2]} user={auth?.user}>
              <JobDetail />
            </ProtectedRoute>
          ),
          children: [
            {
              path: "job-post",
              element: <JobPostSection />,
            },
            {
              path: "applicants",
              element: <ApplicantSection />,
            },
            {
              path: "shortlisted",
              //@ts-ignore
              element: <ShortlistedSection />,
            },
          ],
        },
      ],
    },
    {
      loader: authLoader,
      path: "job-applicant-detail",
      element: <JobApplicantDetail />,
    },
    {
      loader: authLoader,
      path: "/account/account-members/:id",
      element: <AccountMembersDetail />,
    },
    {
      path: "/my-profile",
      loader: authLoader,
      element: <Profile />,
    },
    {
      path: "/change-password",
      loader: authLoader,
      element: <ChangePasswordPage />,
    },
    {
      path: "/job-track",
      loader: authLoader,
      element: <JobTrackPage />,
    },
    {
      path: "/job-track-detail",
      loader: authLoader,
      element: <JobTrackDetail />,
    },
    {
      path: "/settings",
      loader: authLoader,
      element: <SettingsPage />,
    },
    {
      path: "/messages",
      loader: authLoader,
      element: <MembersProfilePage />,
      children: [
        {
          path: ":id",
          loader: authLoader,
          element: <MessageView />,
        },
      ],
    },
    {
      path: "*",
      loader: authLoader,
      element: <DashboardPage />,
    },
  ];

  if (isReferrealAmbassador) {
    layoutChildren.push(
      {
        path: "/network",
        loader: authLoader,
        element: <NetworkPage />,
      },
      {
        path: "/network-detail",
        loader: authLoader,
        element: <NetworkDetailPage />,
      },
      {
        path: "/account",
        loader: authLoader,
        element: <MyAccountSettingsPage />,
      }
    );
  } else {
    layoutChildren.push({
      path: "/account",
      loader: authLoader,
      children: [
        {
          path: "",
          element: <AccountPage />,
          children: [
            {
              path: "/account/account-members",
              element: <AccountMembers />,
            },
            {
              path: "/account/my-company-details",
              element: <MyCompanyDetailsPage />,
            },
            {
              path: "/account/my-account-details",
              element: <MyAccountDetailsPage />,
            },
          ],
        },
      ],
    });
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: layoutChildren,
    },
  ]);

  return {
    router,
  };
}

export default useRoutes;
