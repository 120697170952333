import env from "./env";
const server = env.apiLink;

const api = {
  // auth
  login: `user/login`,
  token: `user/login/token`,
  signup: `user/signup`,
  verifyEmail: "user/verify-email",
  resendOtpverifyEmail: "user/resend-verify-email",
  updateDisableStatus: "user/update-disable-status",
  changePassword: "user/change-password",
  recruiterAddCompany: "recruiter/add-company",
  generateUserNames: "user/generate-usernames",
  updateUserName: "user/update-username",

  // stripe
  getAccountDetails: "payment/account-details",
  getStripeAccountDetails: "payment/stripe-account-details",
  createStripeAccountSession: "payment/create-stripe-account-session",
  createCheckoutSession: "payment/create-checkout-session",
  createPaymentIntent: "payment/create-payment-intent",
  getReferrals: "recruiter/referrals",
  createCustomer: "payment/create-customer",
  addCardPaymentMethod: "payment/add-card",
  addBankDetails: "payment/attach-bank",
  getBankLists: "payment/banks",
  makeDefaultBank: "payment/make-default",
  updateBankDetails: "payment/update-bank",
  fetchBankDetails: "payment/bank-details",
  deleteBank: "payment/bank",
  getBalanceDetails: "payment/balance",
  withDrawBalance: "payment/withdrawl",
  fetchPaymentMethods: "payment/payment-methods",
  addCustomerBankAccount: "payment/add-bank-account",
  deletePaymentMethod: "payment/payment-method",
  setDefaultPaymentMethod: "payment/set-default",

  //candidate
  getCandidates: "contact/get-candidates",
  getRegisterCandidates: "contact/registered-candidates",
  getCandidateAssessment: "contact/candidate-assessments",
  getCandidateDetails: "contact/candidate-details",
  addCandidate: "contact/add-candidate",
  updateCandidate: "contact/update-candidate",
  deleteCandidate: "contact/delete-candidate",
  addExperience: "contact/add-experience",
  updateExperience: "contact/update-experience",
  getExperienceList: "contact/experiences",
  getExperience: "contact/get-experience",
  deleteExperience: "contact/delete-experience",
  getContactSkills: "contact/candidate-skills",
  getRestrictedCandidate: "contact/restricted-candidate-details",
  getCandidateAppliedJobs: "/job/candidate-jobs",

  //company
  getCompany: "",
  companyList: "company/lists",
  addCompany: "company/add",
  updateCompany: "company/update",
  deleteCompany: "company/delete",

  //company contact
  getCompanyContact: "company/contacts",
  addCompanyContact: "company/add-contact",
  deleteCompanyContact: "company/delete-contact",
  updateCompanyContact: "company/update-contact",

  createRecruiterProfile: `recruiter-profile`,
  resetPassword: `auth/reset-password`,
  registerEmailVerify: `auth/email-verify`,
  forgotPassword: `user/forgot-password`,
  forgotPasswordVerify: `user/forgot-password/verify`,
  resetForgotPassword: `user/reset-forgot-password`,

  //assessment
  listAssessment: `assessment/lists`,
  createAssessment: `assessment/create-assessment`,
  deleteAssessment: `assessment/delete-assessment`,
  assessmentDetails: `assessment/details`,
  updateAssessment: `assessment/update-assessment`,
  deleteAssessment: `assessment/delete-assessment`,
  createCompany: `assessment/create-company`,
  sendAssessment: `assessment/send-assessment`,

  //assessment section
  createSection: `assessment/create-section`,
  updateSection: `assessment/update-section`,
  getSection: `assessment/section`,
  updateSection: `assessment/update-section`,
  deleteSection: `assessment/delete-section`,

  //assessment question
  updateQuestion: `assessment/update-question`,
  createQuestion: `assessment/create-question`,
  getQuestionBySectionId: `assessment/question`,
  updateQuestion: `assessment/update-question`,
  deleteQuestion: `assessment/delete-question`,

  //Job
  getJobList: "job/lists",
  getJobDetails: "job/details",
  addJob: "job/add",
  shareJob: "job/share-job",
  verifyJob: "user/verify-share-job",
  verifyJobStatus: "user/share-job-verify-status",
  updateJob: "job/update",
  deleteJob: "job/delete/",
  publishUnpublish: "job/publish-unpublish",
  applyJob: "job/apply-job",
  applicantList: "job/applicants/lists",
  jobMessage: "job/message",
  jobMessageList: "job/message/lists",
  jobMembersProfile: "job/get-members-profile",
  jobAddTracker: "job/add-tracker",
  jobGetTracker: "job/get-trackers",
  jobUpdateApplicant: "job/update-applicant",
  unseenMessageCount: "job/get-unseen-message-count",
  updateMessageSeen: "job/messages/seen",

  // Education or Certificate
  addCertificateOrEducation: "contact/add-certificate",
  updateCertificateOrEducation: "contact/update-certificate",
  getCertificateOrEducation: "contact/certificates",

  //skill
  addSkill: "skill/add",
  updateSkill: "skill/update",
  deleteSkill: "skill/delete/",
  skillSearch: "skill/search",
  getSkillIndividualPercentage: "skill/get-individual-percentages",
  listSkill: "skill/lists",

  getMatchedCandidate: "skill/get-matched-candidates",

  //images
  profileImage: "medias/images-direct-url",

  //recruiter
  accountMembers: "recruiter/members",
  recruiterDetail: "recruiter/details",
  recruiterProfileUpdate: "recruiter/update-profile",
  approvalRequest: "recruiter/update-request",
  suspendAccount: "recruiter/suspend-account",
  userAdmin: "recruiter/make-admin",
  deleteRecruiterAccount: `user/deactivate`,

  //referral
  referralProfileUpdate: "referral/update-profile",
  referralContacts: "referral/contacts",
  referralMatchedContacts: "referral/matched-contacts",
  referralMatchedContactDetails: "referral/matched-contact-details",
  inviteUserEmail: "referral/invite",
  referralMatchedJobs: "referral/contact-matches-jobs",
  verifyReferralInvite: "user/verify-invitee",
  inviteReferral: "user/invite-referral",
  contactMatchesJob: "referral/contacts-matches-jobs",
  contactMatchesSkills: "referral/contact-matches-skills",
  getReferralTrackers: "job/get-referral-trackers",

  //notificaiton
  getNotificationList:'/job/notifications/lists',
  updateNotification:'/job/notification/update'
};

export default api;
